import * as React from "react";
import { useState } from "react";
import { observer } from 'mobx-react';
import useStores from "../hooks/use-stores";

const australianStates: string[] = [
  "Australian Capital Territory", "New South Wales", "Victoria", "Queensland",
  "South Australia", "Western Australia", "Tasmania", "Northern Territory"
];
const australianStateCodes: string[] = ["ACT", "NSW", "VIC", "QLD", "SA", "WA", "TAS", "NT"];

const TestAddress = observer(() => {
  // create form variables -- Address
  const [unitNumber, setUnitNumber] = useState<number>(0);
  const [streetNumber, setStreetNumber] = useState<string>("");
  const [streetName, setStreetName] = useState<string>("");
  const [suburbName, setSuburbName] = useState<string>("");
  const [postcode, setPostcode] = useState<string>("");
  const [stateName, setStateName] = useState<string>("");
  const [stateCode, setStateCode] = useState<string>("");
  // create id variable
  const [propertyId, setPropertyId] = useState<number>();
  // const index to control two variables at the same time
  const [stateIndex, setStateIndex] = useState<string>("0");
  React.useEffect(() => {
    setStateName(australianStates[parseInt(stateIndex)]);
    setStateCode(australianStateCodes[parseInt(stateIndex)]);
  }, [stateIndex])
  React.useEffect(() => {
    console.log(`${unitNumber} ${streetNumber} ${streetName} ${suburbName} ${postcode} 
    ${australianStates[parseInt(stateIndex)]} ${australianStateCodes[parseInt(stateIndex)]}`)
  }, [stateIndex])
  // create address in back-end
  const { addressStore } = useStores();
  const createAddress = () => {
    addressStore.createAddress({
      unitNumber,
      streetNumber,
      streetName,
      suburb: {
        name: suburbName,
        postcode,
        state: stateName,
        stateCode,
      },
    })
  }
  // do the steps by clicking
  const showID = () => {
    console.log("re 1:", addressStore.addressInfo && addressStore.addressInfo.propertyId);
    setPropertyId(addressStore.addressInfo.propertyId);
    //   propertyId = addressStore.addressInfo.propertyId;
  }
  const showSetResult = () => {
    console.log("re 2:", propertyId, typeof (propertyId));
  }
  // do the steps by useEffect
  React.useEffect(() => {
    console.log("re 1:", addressStore.addressInfo.propertyId);
    if (addressStore.addressInfo.propertyId !== 0) {
      setPropertyId(addressStore.addressInfo.propertyId);
    }
    // setPropertyId(addressStore.addressInfo.propertyId);
  }, [addressStore.addressInfo.propertyId])
  React.useEffect(() => {
    console.log("re 2:", propertyId, typeof (propertyId));
  }, [propertyId])

  // const getAddressList = () => {
  //   addressStore.getAddressList()
  // }
  // React.useEffect(() => {
  //   addressStore.getAddressList();
  // }, []);
  return (
    <>
      <input type="number" placeholder="unitNumber"
        value={unitNumber} onChange={(event) => setUnitNumber(parseInt(event.target.value))} />
      <input type="text" placeholder="streetNumber"
        value={streetNumber} onChange={(event) => setStreetNumber(event.target.value)} />
      <input type="text" placeholder="streetName"
        value={streetName} onChange={(event) => setStreetName(event.target.value)} />
      <input type="text" placeholder="suburbName"
        value={suburbName} onChange={(event) => setSuburbName(event.target.value)} />
      <input type="text" placeholder="postcode"
        value={postcode} onChange={(event) => setPostcode(event.target.value)} />
      <select defaultValue="choose state" onChange={(event) => setStateIndex(event.target.value)} placeholder="choose state">
        <option value={"choose state"} disabled hidden>Choose state</option>
        {australianStates.map((australianState, index) =>
          <option key={index} value={index}>{australianState}</option>
        )}
      </select>
      <button onClick={createAddress}>|Submit|</button>
      <button onClick={showID}>|show propertyId|</button>
      <button onClick={showSetResult}>|show result|</button>
    </>
  )
})
export default TestAddress;